import React, { useEffect, useState } from 'react'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import { Col, Label, Row } from 'reactstrap'
import Select from 'react-select';
import { useSelector } from 'react-redux';

const brandOptions = []
const categoryOptions = []

export default function Form(props) {

    const { product, setProduct, setbrandId, setCategoryId } = props
    const brands = useSelector(state => state.Brands.brands)
    const categories = useSelector(state => state.Families.categories)
    const [selectedBrand, setselectedBrand] = useState(null)
    const [selectedCategory, setselectedCategory] = useState(null)

    function handleSelectCategory(selectedCategory) {
        setselectedCategory(selectedCategory)
    }

    function handleSelectBrand(selectedBrand) {
        setselectedBrand(selectedBrand)
    }

    useEffect(() => {
        if (brandOptions.length===0) {
            brands.map((brand) => {
                brandOptions.push({ ...brand, value: brand.name, label: brand.name })
            })
        }
        if (categoryOptions.length===0) {
            categories.map((category) => {
                categoryOptions.push({ ...category, value: category.name, label: category.name })
            })
        }
    }, [])

    useEffect(() => {
        brandOptions.find((str) => {
            if (str.value === product.brand?.name)
                setselectedBrand(str)
        });
        categoryOptions.find((str) => {
            if (str.value === product.category?.name)
                setselectedCategory(str)
        });
        if (product.category?.name) {
            setCategoryId(product?.category?.id)
        }
        if (product.category?.name) {
            setbrandId(product.brand?.id)
        }
    }, [product])

    return (
        <>
            
                <Col sm="6">
                    <AvField
                        className="mb-3"
                        name="label"
                        label="Label"
                        placeholder="Label"
                        type="text"
                        errorMessage="Label is required."
                        validate={{ required: { value: true } }}
                        value={product.label}
                        onChange={(e) => { setProduct({ ...product, label: e.target.value }) }}
                    />
                </Col>
                <Col sm="6">
                    <div className="mb-3">
                        <Label>Brand</Label>
                        <Select
                            placeholder="Type"
                            options={brandOptions}
                            classNamePrefix="select2-selection"
                            value={selectedBrand}
                            onChange={(e) => { handleSelectBrand(); setbrandId(e.id); }}
                        />
                    </div>
                </Col>
                <Col sm="6">
                    <AvField
                        type="select"
                        name="select"
                        label="Typology"
                        placeholder="test"
                        errorMessage="Typology is required."
                        validate={{ required: { value: true } }}
                        value={product.typology}
                        onChange={(e) => { setProduct({ ...product, typology: parseInt(e.target.value) }) }}
                    >
                        <option>Typologies</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </AvField>
                </Col>
                <Col sm="6">
                    <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                            placeholder="Type"
                            options={categoryOptions}
                            classNamePrefix="select2-selection"
                            value={selectedCategory}
                            onChange={(e) => { handleSelectCategory(); setCategoryId(e.id); }}
                        />
                    </div>
                </Col>
                <Col sm="6">
                    <AvField
                        className="mb-3"
                        name="barcode"
                        label="Barcode"
                        placeholder="Barcode"
                        type="text"
                        errorMessage="Barcode is required."
                        validate={{ required: { value: true } }}
                        value={product.barcode}
                        onChange={(e) => { setProduct({ ...product, barcode: e.target.value }) }}
                    />
                </Col>
        </>
    )
}
