import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export let api;

/**
 * @description config axios
 */
const token = localStorage.getItem('authUser'); // token from localStorage
if (token) {
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
} else {
  api = axios.create({
    baseURL: API_URL,
  })
}

export const register_mail = (user, password) => {
  return {
    receiver: user.email,
    subject: "Account confirmation email",
    text:"Dear " + user.first_name + " " + user.last_name +
         "we have just created your account to access to the gtm platform and here are your credentials."+
         "username: "+ user.username + 
         "password: "+ password +
         "Welcome to our plateform. Cordially."
  }
}

export const reset_password_mail = (user, password) => {
  return {
    receiver: user.email,
    subject: "GTM new E-mail",
    text:"Dear " + user.first_name + " " + user.last_name +
         "we have just updated your account to access to the gtm platform and here are your credentials."+
         "username: "+ user.username + 
         "password: "+ password +
         "Cordially."
  }
}