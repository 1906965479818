import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/User/ProfilePage"
import Login from "../pages/Authentication/Login"
import Dashboard from "../pages/Dashboard/index"
import UsersList from "../pages/User"
import RolesPermissions from "pages/Roles-Permissions"
import NotFoundPage from "shared/NotFoundPage"
import PosList from "pages/POS"
import BrandsList from "pages/Brand"
import CategoriesFamilies from "pages/Categories-Families"
import ProductsList from "pages/Products"
import StoreGroupeList from "pages/StoreGroup"

const userRoutes = [
  // Dashboard route
  { path: "/dashboard", component: Dashboard },
  // user routes
  { path: "/users", component: UsersList},
  { path: "/profile", component: UserProfile },
  //settings routes
  { path: "/permissions", component: RolesPermissions},
  { path: "/categories", component: CategoriesFamilies},
  { path: "/storeGroup", component: StoreGroupeList},
  { path: "/brands", component: BrandsList },
  // POS routes
  { path: "/stores", component: PosList},
  // Product routes
  { path: "/products", component: ProductsList},
  // standard
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/*", exact: true, component: () => <Redirect to="/404" /> },
]

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/404", component: NotFoundPage},
]

export { userRoutes, authRoutes }
