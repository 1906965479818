import { API_URL, api } from "helpers/consts";
import { uploadFile } from "helpers/services/filesManagement";

/**
 * 
 * @returns Brand list
 */
export const getBrands = async () => {
    const res = await api.get(`${API_URL}/brand/active`);
    return res.data;
};

/**
 * 
 * @param {Brand} brand 
 * @returns 
 */
export const upsertBrand = async (brand, file) => {
    if (!file) {
        await api.post(`${API_URL}/brand`, brand);
    } else {
        await uploadFile(file, "brands").then(async (file)=>{
            return await api.post(`${API_URL}/brand`, {...brand, path: file.data.fileDownloadUri});
        })
    }
  
};