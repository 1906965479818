import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Link } from 'react-router-dom'
import { Col, Container, Modal, Row, UncontrolledTooltip, Card, CardBody, CardTitle } from 'reactstrap'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Maps from './Maps'

export default function DetailModal(props) {

    const { store } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        document.body.classList.add("no_padding")
    }

    return (
        <>
            <Link
                to="#"
                onClick={() => { tog_fullscreen() }}
                style={{ fontSize: "1.3rem", marginRight: "1rem" }}
            >
                <i className="mdi mdi-eye-minus-outline" id="detailtooltip" />
                <UncontrolledTooltip placement="top" target="detailtooltip">
                    View Detail
                </UncontrolledTooltip>
            </Link>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                    tog_fullscreen()
                }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Store Detail
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_fullscreen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ backgroundColor: "#eff2f7" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>Store detail</title>
                        </MetaTags>
                        <Container fluid>
                            <Row style={{ height: "30rem" }}>
                                <Col lg="7">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Team Members</CardTitle>


                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg="5">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Location</CardTitle>
                                            <Maps lat={0} lng={0} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "4rem" }}>
                                <Col lg="6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Location</CardTitle>
                                            <Maps lat={0} lng={0} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Location</CardTitle>
                                            <Maps lat={0} lng={0} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_fullscreen()
                        }}
                        className="btn btn-danger waves-effect"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </Modal>
        </>
    )
}
