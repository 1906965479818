import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

export default function PositionedSweetAlert(props) {

    const {setAlert} = props;
    return (
        <SweetAlert
            title="profile updated successfully"
            timeout={2000}
            style={{
                position: "absolute",
                top: "0px",
                right: "0px",
            }}
            showCloseButton={false}
            showConfirm={false}
            success
            onConfirm={() => {
                setAlert(false)
            }}
        ></SweetAlert>
    )
}
