import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

export default function SweetAlertResetPassword(props) {

    const {password_alert, setpassword_alert, currentUser, resetPassword} = props;
    const [success_dlg, setsuccess_dlg] = useState(false)
    const [error_dlg, seterror_dlg] = useState(false)
    const [dynamic_title, setdynamic_title] = useState("")
    const [dynamic_description, setdynamic_description] = useState("")
    
    return (
        <>
            {password_alert &&
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Reset"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        resetPassword(currentUser, currentUser.role.id)
                        setpassword_alert(false)
                        setsuccess_dlg(true)
                        setdynamic_title("Reset")
                        setdynamic_description("the password has been reset")
                    }}
                    onCancel={() => { setpassword_alert(false); }}
                >
                    reset password
                </SweetAlert>
            }
            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {error_dlg ? (
                <SweetAlert
                    error
                    title={dynamic_title}
                    onConfirm={() => {
                        seterror_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}
        </>
    )
}
