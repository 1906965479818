import { API_URL, api } from "helpers/consts";

/**
 * 
 * @returns Family list
 */
 export const getFamilies = async () => {
    const res = await api.get(`${API_URL}/family`)
    return res.data;
}

/**
 * 
 * @param {Family} family 
 * @returns Created Family
 */
 export const upsertFamily = async (family) => {
    const res = await api.post(`${API_URL}/family`, family)
    return res.data;
}

/**
 * 
 * @returns Category list
 */
 export const getCategories = async () => {
    const res = await api.get(`${API_URL}/category`)
    return res.data;
}

/**
 * 
 * @param {Category} category 
 * @returns Created Category
 */
 export const upsertCategory = async (category) => {
    const res = await api.post(`${API_URL}/category`, category)
    return res.data;
}