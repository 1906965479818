import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import CustomField from 'pages/customField'
import CreateValues from 'pages/customField/CreateValues'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Label, Modal, Row } from 'reactstrap'
import ImageUpload from 'shared/ImageUpload'
import MutipleImagesUpload from 'shared/MutipleImagesUpload'
import Form from './Form'
import Maps from './Maps'
import { createStorePictures, upsertStore } from "store/pos/services";
import { upsertCustomFieldValues } from "store/customFields/services";
import { uploadFile } from 'helpers/services/filesManagement'

export default function CreateModal(props) {

    const { getList } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [store, setStore] = useState({})
    const [visibleImages, setVisibleImages] = useState(0)
    const [selectedFile, setselectedFile] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])
    const [customFieldValues, setCustomFieldValues] = useState([])

    const createStore = (store) => {
        if (selectedFile.length === 0) {
            //create store
            upsertStore(store).then((res) => {
                //create customFields values
                upsertCustomFieldValues(customFieldValues, res)
                if (selectedFiles.length > 0) {
                    //upload store gallery
                    createStorePictures(selectedFiles, parseInt(res.id), "stores")
                }
            })
            getList()
            tog_fullscreen()
        }
        else {
            //upload store picture
            uploadFile(selectedFile, "stores").then((res) => {
                //create store
                upsertStore({ ...store, picture: res.data.fileDownloadUri }).then((result) => {
                    //create customFields values
                    upsertCustomFieldValues(customFieldValues, result)
                    if (selectedFiles.length > 0) {
                        //upload store gallery
                        createStorePictures(selectedFiles, parseInt(res.id), "stores")
                    }
                })
            });
            getList()
            tog_fullscreen()
        }
    }

    function success(pos) {
        var crd = pos.coords;
        setStore({
            ...store,
            lat: crd.latitude,
            lng: crd.longitude
        })
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        document.body.classList.add("no_padding")
    }

    return (
        <>
            <Link to="#">
                <i
                    className="bx bx-list-plus"
                    style={{ fontSize: "25px", float: "right", marginTop: "-2rem", cursor: "pointer", color: '#556EE6' }}
                    data-toggle="modal"
                    onClick={() => { tog_fullscreen() }}
                ></i>
            </Link>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Create store
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        onValidSubmit={() => { createStore(store) }}
                                    >
                                        {/* Form component */}
                                        <Form store={store} setStore={setStore} />
                                        <Row>
                                            <Button
                                                className="waves-effect waves-effect waves-light btn-outline"
                                                color="primary"
                                                outline
                                                style={{ width: "98%", height: "3rem", fontSize: "1.3rem", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }}
                                                type="button"
                                                onClick={() => { navigator.geolocation.getCurrentPosition(success) }}
                                            >
                                                <i className="bx bxs-map" data-toggle="modal"></i>
                                                {" "} get current location
                                            </Button>
                                        </Row>
                                        {store.lat && store.lng &&
                                            <Row style={{ height: "15rem", width: "100%" }}>
                                                <Maps lat={store.lat} lng={store.lng} />
                                            </Row>
                                        }
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col sm={6}>
                                                <h5>Store picture :</h5>
                                                {/* Upload component */}
                                                <ImageUpload setselectedFile={setselectedFile} />
                                            </Col>
                                            {selectedFile &&
                                                <Col>
                                                    <img
                                                        style={{ width: 'auto', height: '14rem', marginTop: "2.3rem" }}
                                                        data-dz-thumbnail=""
                                                        className="avatar-sm rounded bg-light"
                                                        alt={selectedFile.name}
                                                        src={selectedFile.preview}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                        <Row style={{ marginTop: "2rem" }}>
                                            <Col>
                                                <h5 className="mb-3">images :</h5>
                                                <div className="zoom-gallery">
                                                    {selectedFiles.length > 0 &&
                                                        <i
                                                            className="mdi mdi-chevron-left"
                                                            style={{ fontSize: "2.5rem", cursor: "pointer", float: "left", marginTop: "3rem", marginRight: "2.5%" }}
                                                            onClick={() => { visibleImages - 9 >= 0 && setVisibleImages(visibleImages - 9) }}
                                                        />
                                                    }
                                                    {selectedFiles.map((picture, index) => (
                                                        <>
                                                            {index < visibleImages + 9 && index >= visibleImages &&
                                                                <img

                                                                    style={{ width: "9%", height: "10rem", marginRight: "1%" }}
                                                                    data-dz-thumbnail=""
                                                                    className="avatar-sm rounded bg-light"
                                                                    alt={selectedFiles && picture.name}
                                                                    src={selectedFiles && picture.preview}
                                                                />
                                                            }
                                                        </>
                                                    ))}
                                                    {selectedFiles.length > 0 &&
                                                        <i
                                                            className="mdi mdi-chevron-right"
                                                            style={{ fontSize: "2.5rem", cursor: "pointer", float: "right", marginTop: "3rem" }}
                                                            onClick={() => { visibleImages + 9 <= selectedFiles?.length && setVisibleImages(visibleImages + 9) }}
                                                        />
                                                    }
                                                </div>
                                                {/* Multiple upload component */}
                                                <MutipleImagesUpload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                                            </Col>
                                        </Row>
                                        {/* custom values form */}
                                        <CreateValues customFieldValues={customFieldValues} setCustomFieldValues={setCustomFieldValues} />
                                        <div className="d-flex flex-wrap gap-2" style={{ float: "right", marginTop: "2rem" }}>
                                            {/* create custom field component */}
                                            <CustomField table_name="Store" />
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn waves-effect waves-light"
                                            >
                                                Create store
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
