import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { Button, FormGroup, Label, Modal, Row } from "reactstrap"
import { getUsersList } from "store/user/actions";
import { getRolesList } from "store/roles-permissions/actions";
import generator from "generate-password";
import bcrypt from "bcryptjs"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { upsertUser } from "store/user/services";

export default function UpdateModal(props) {

    const { modal, setModal, tog_Modal, currentUser, setUsersList, setFiltredUsers } = props;

    const dispatch = useDispatch();
    const [roles, setRoles] = useState(null);
    const [role, setRole] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({});

    useEffect(async () => {
        setRoles(dispatch(await getRolesList()).payload.roles);
        if (modal) {
            setPassword(generator.generate({
                length: 10,
                numbers: true,
                strict: true,
                symbols: true
            }));
        }
    }, [modal])

    useEffect(() => {
        setUser(currentUser);
        setRole(currentUser.role)
    }, [currentUser])

    const getUsers = async () => {
        return dispatch(await getUsersList()).payload;
    }

    const updateUser = (user, roleId) => {
        upsertUser(user, roleId)
            .then(async () => {
                await getUsers().then((res) => {
                    setUsersList(res.usersList);
                    setFiltredUsers(res.usersList);
                })
                setModal(false);
            })
    }

    return (
        <Modal
            isOpen={modal}
            toggle={() => { tog_Modal() }}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    Create new user
                </h5>
                <button
                    type="button"
                    onClick={() => setModal(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                </button>
            </div>
            <div className="modal-body">

                <AvForm
                    onValidSubmit={() => {
                        updateUser(user, role)
                    }}
                >
                    <AvField
                        className="mb-3"
                        name="First name"
                        label="First name"
                        placeholder="First name"
                        type="text"
                        errorMessage="First name is required."
                        validate={{ required: { value: true } }}
                        value={user.first_name}
                        onChange={(e) => { setUser({ ...user, first_name: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="Last name"
                        label="Last name"
                        placeholder="Last name"
                        type="text"
                        errorMessage="Last name is required."
                        validate={{ required: { value: true } }}
                        value={user.last_name}
                        onChange={(e) => { setUser({ ...user, last_name: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        errorMessage="Invalid Email"
                        validate={{
                            required: { value: true },
                            email: { value: true },
                        }}
                        value={user.email}
                        onChange={(e) => { setUser({ ...user, email: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="Phone number"
                        label="Phone number"
                        placeholder="Phone number"
                        type="number"
                        errorMessage="Enter Only Number"
                        validate={{
                            required: { value: true },
                            pattern: {
                                value: "^[0-9]{8}$",
                                errorMessage: "the number must be composed of 8 digits",
                            },
                        }}
                        value={user.phone_number}
                        onChange={(e) => { setUser({ ...user, phone_number: e.target.value }); }}
                    />
                    <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Gender</Label>
                        <Row>
                            <div style={{ marginLeft: '1rem' }} className="form-check form-radio-outline form-radio-primary mb-3">
                                <label className="form-check-label"> Male </label>
                                <input
                                    type="radio"
                                    name="customRadiooutlinecolor1"
                                    className="form-check-input"
                                    value={user.gender}
                                    checked={user.gender === 'M'}
                                    onChange={() => { setUser({ ...user, gender: 'M' }); }}
                                />
                            </div>
                            <div style={{ marginLeft: '5rem', marginTop: '-2.2rem' }} className="form-check form-radio-outline form-radio-danger mb-3">
                                <label className="form-check-label"> Female </label>
                                <input
                                    type="radio"
                                    name="customRadiooutlinecolor1"
                                    className="form-check-input"
                                    value={user.gender}
                                    checked={user.gender === 'F'}
                                    onChange={() => { setUser({ ...user, gender: 'F' }); }}
                                />
                            </div>
                        </Row>
                    </div>
                    <AvField
                        className="mb-3"
                        name="Role"
                        label="Role"
                        placeholder="Enter Only number"
                        type="select"
                        errorMessage="Role is required"
                        validate={{
                            required: { value: true },
                        }}
                        value={role?.id}
                        onChange={(e) => { setRole(e.target.value); }}
                    >
                        <option>Choose role...</option>
                        {roles?.map((r, index) => (
                            <option key={index} value={r.id}>{r.role}</option>
                        ))}

                    </AvField>
                    <FormGroup className="mb-0" style={{ float: 'right' }}>
                        <Button
                            type="reset"
                            color="danger"
                            onClick={() => { setModal(false); }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                        >
                            Update User
                        </Button>
                    </FormGroup>
                </AvForm>
            </div>
        </Modal>
    )
}