import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getCustomFieldsByTableNameAsync } from 'store/customFields/actions'

export default function CreateValues(props) {

    const { customFieldValues, setCustomFieldValues } = props
    const [customFields, setCustomFields] = useState(null)
    const dispatch = useDispatch()
    const fields = useSelector(state => state.CustomFields.fields)

    const onUpdate = (e, i) => {
        customFieldValues[i].value = e.target.value
        setCustomFieldValues(customFieldValues)
    }

    useEffect(async () => {
        dispatch(await getCustomFieldsByTableNameAsync("Store"))
    }, [])

    useEffect(() => {
        setCustomFields(fields)
    }, [fields])

    useEffect(() => {
        if (customFieldValues.length === 0) {
            customFields?.map((cf) => {
                setCustomFieldValues(customFieldValues => [...customFieldValues, { value: "", customField: cf, store: {} }])
            })
        }
    }, [customFields])

    return (
        <div>
            <Row>
                <h5 className="mb-3" style={{ marginTop: "2rem", marginBlock: "2rem" }}>Custom fields :</h5>
            </Row>
            {customFields?.map((cf, index) => (
                <div key={index}>
                    {index % 2 === 0 &&
                        <Row>
                            <Col sm={6}>
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                    {customFields[index].name}
                                </label>
                                {customFields[index].type === "Date" &&
                                    <input
                                        className="form-control"
                                        type="date"
                                        placeholder={customFields[index].name}
                                        name={"name"+index}
                                        onChange={(e) => { onUpdate(e, index) }}
                                    />
                                }
                                {(customFields[index].type === "Double" || customFields[index].type === "Integer") &&
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder={customFields[index].name}
                                        name={"name"+index}
                                        onChange={(e) => { onUpdate(e, index) }}
                                    />
                                }
                                {customFields[index].type === "String" &&
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={customFields[index].name}
                                        name={"name"+index}
                                        onChange={(e) => { onUpdate(e, index) }}
                                    />
                                }
                            </Col>
                            <Col>
                                <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                    {customFields[index + 1]?.name}
                                </label>
                                {customFields[index + 1]?.type === "Date" &&
                                    <input
                                        className="form-control"
                                        type="date"
                                        placeholder={customFields[index+1].name}
                                        name={"name"+index+1}
                                        onChange={(e) => { onUpdate(e, index + 1) }}
                                    />
                                }
                                {(customFields[index + 1]?.type === "Double" || customFields[index + 1]?.type === "Integer") &&
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder={customFields[index+1].name}
                                        name={"name"+index+1}
                                        onChange={(e) => { onUpdate(e, index + 1) }}
                                    />
                                }
                                {customFields[index + 1]?.type === "String" && customFieldValues.length>0 &&
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={customFields[index].name}
                                        name={"name"+index+1}
                                        onChange={(e) => { onUpdate(e, index + 1) }}
                                    />
                                }
                            </Col>
                        </Row>
                    }
                </div>
            ))}
        </div>
    )
}