import { API_URL, api } from "helpers/consts";
import { uploadFile } from "helpers/services/filesManagement";

/**
 * 
 * @returns StoreGroup list
 */
export const getStoreGroups = async () => {
    const res = await api.get(`${API_URL}/storeGroup`);
    return res.data;
};

/**
 * 
 * @param {Brand} brand 
 * @returns 
 */
export const upsertStoreGroup = async (storeGroup, file) => {
    if (!file) {
        await api.post(`${API_URL}/storeGroup`, storeGroup);
    } else {
        await uploadFile(file, "groups").then(async (file)=>{
            return await api.post(`${API_URL}/storeGroup`, {...storeGroup, path: file.data.fileDownloadUri});
        })
    }
  
};