import React, { useEffect, useState } from 'react'
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import CustomField from 'pages/customField';
import UpdateValues from 'pages/customField/UpdateValues';
import { Button, Col, Modal, Row, UncontrolledTooltip } from 'reactstrap'
import ImageUpload from 'shared/ImageUpload';
import MutipleImagesUpload from 'shared/MutipleImagesUpload';
import Form from './Form';
import Maps from './Maps';
import { upsertCustomFieldValues } from "store/customFields/services";
import { uploadFile } from "helpers/services/filesManagement";
import { createStorePictures, upsertStore } from "store/pos/services";
import { Link } from 'react-router-dom';

export default function UpdateModal(props) {

    const { currentStore, getList } = props;
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [selectedFile, setselectedFile] = useState(null)
    const [selectedFiles, setselectedFiles] = useState([])
    const [store, setStore] = useState(currentStore)
    const [visibleImages, setVisibleImages] = useState(0)
    const [customFieldValues, setCustomFieldValues] = useState([])

    const updateStore = (store) => {
        if (!selectedFile) {
            upsertStore(store).then((result) => {
                //create customFields values
                upsertCustomFieldValues(customFieldValues, result).then(() => {
                    getList()
                    tog_fullscreen()
                })
            });
        }
        else if (selectedFiles.length > 0) {
            createStorePictures(selectedFiles, props.match.params.id, "stores").then(() => {
                getList()
                tog_fullscreen()
            })
        } else {
            uploadFile(selectedFile, "stores").then((res) => {
                upsertStore({ ...store, picture: res.data.fileDownloadUri }).then((result) => {
                    //create customFields values
                    upsertCustomFieldValues(customFieldValues, result).then(() => {
                        getList()
                        tog_fullscreen()
                    })
                });
            });
            setselectedFile(null);
        }
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        document.body.classList.add("no_padding")
    }

    function success(pos) {
        let crd = pos.coords;
        setStore({
            ...store,
            lat: crd.latitude,
            lng: crd.longitude
        })
    }

    useEffect(() => {
        setCustomFieldValues(store.customFieldValues)
    }, [store])

    return (
        <>
            <Link to="#" onClick={() => { tog_fullscreen() }} style={{ fontSize: "1.3rem" }}>
                <i className="bx bx-edit-alt" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                </UncontrolledTooltip>
            </Link>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Update Store
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col xs="12">
                            <AvForm
                                onValidSubmit={() => { updateStore(store) }}
                            >
                                {/* Form component */}
                                <Form store={store} setStore={setStore} />
                                <Row>
                                    <Button
                                        className="waves-effect waves-effect waves-light btn-outline"
                                        color="primary"
                                        outline
                                        style={{ width: "98%", height: "3rem", fontSize: "1.3rem", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }}
                                        type="button"
                                        onClick={() => { navigator.geolocation.getCurrentPosition(success) }}
                                    >
                                        <i className="bx bxs-map" data-toggle="modal"></i>
                                        {" "} update location
                                    </Button>
                                </Row>
                                {store.lat && store.lng &&
                                    <Row style={{ height: "15rem", width: "100%" }}>
                                        <Maps lat={store.lat} lng={store.lng} />
                                    </Row>
                                }
                                <Row style={{ marginTop: "2rem" }}>
                                    <h5 className="mb-3">Store picture :</h5>
                                    <Col sm={6}>
                                        {/* Upload component */}
                                        <ImageUpload setselectedFile={setselectedFile} />
                                    </Col>
                                    <Col>
                                        {(store.picture || selectedFile?.preview) &&
                                            <img
                                                style={{ width: "auto", height: "14rem" }}
                                                data-dz-thumbnail=""
                                                className="avatar-sm rounded bg-light"
                                                alt={selectedFile ? (selectedFile.name) : (store.id)}
                                                src={(store.picture && !selectedFile?.preview) ? (store.picture) : (selectedFile?.preview)}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "2rem" }}>
                                    <Col>
                                        <h5 className="mb-3">images :</h5>
                                        {store.pictures &&
                                            <div className="zoom-gallery" style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                                                {selectedFiles.length > 0 &&
                                                    <i
                                                        className="mdi mdi-chevron-left"
                                                        style={{ fontSize: "2.4rem", cursor: "pointer", float: "left", marginTop: "3rem", marginRight: "2.7%" }}
                                                        onClick={() => { visibleImages - 9 >= 0 && setVisibleImages(visibleImages - 9) }}
                                                    />
                                                }
                                                {selectedFiles.map((picture, index) => (
                                                    <>
                                                        {index < visibleImages + 9 && index >= visibleImages &&
                                                            <img
                                                                key={index}
                                                                style={{ width: "9%", height: "10rem", marginRight: "1%" }}
                                                                data-dz-thumbnail=""
                                                                className="avatar-sm rounded bg-light"
                                                                alt={selectedFiles && picture.name}
                                                                src={selectedFiles && picture.preview}
                                                            />
                                                        }
                                                    </>
                                                ))}
                                                {selectedFiles.length > 0 &&
                                                    <i
                                                        className="mdi mdi-chevron-right"
                                                        style={{ fontSize: "2.4rem", cursor: "pointer", float: "right", marginTop: "3rem" }}
                                                        onClick={() => { visibleImages + 9 <= selectedFiles?.length && setVisibleImages(visibleImages + 9) }}
                                                    />
                                                }
                                            </div>
                                        }
                                        {/* Multiple upload component */}
                                        <MutipleImagesUpload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                                    </Col>
                                </Row>
                                {/* custom values form */}
                                <UpdateValues customFieldValues={customFieldValues} setCustomFieldValues={setCustomFieldValues} store={store} />
                                <div className="d-flex flex-wrap gap-2" style={{ float: "right", marginTop: "1rem" }}>
                                    {/* create custom field component */}
                                    <CustomField table_name="Store" />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn waves-effect waves-light"
                                    >
                                        Update store
                                    </Button>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
