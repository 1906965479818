import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone';
import { Col, Row, TabPane, FormGroup, Button } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { uploadFile } from 'helpers/services/filesManagement';

export default function UpdateInformations(props) {

    const { state, user, setDisableEdit, DisableEdit, updateProfile } = props
    const [selectedFiles, setselectedFiles] = useState(null)
    const [newUser, setNewUser] = useState(null)

    function handleAcceptedFiles(files) {
        setselectedFiles(Object.assign(files[0], {
            preview: URL.createObjectURL(files[0]),
            formattedSize: formatBytes(files[0].size),
        }))
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const updateInformations = async (user) => {
        if (!selectedFiles) {
            await updateProfile(user, user.role.id);
        } else {
            await uploadFile(selectedFiles, "users")
                .then(async (res) => {
                    await updateProfile({ ...user, profile_picture: res.data.fileDownloadUri }, user.role.id);
                });
                setselectedFiles(null)
        }
    }

    useEffect(() => {
        setNewUser(user)
    }, [user])

    useEffect(() => {
        if (selectedFiles || state.user?.first_name !== newUser?.first_name || state.user?.last_name !== newUser?.last_name || state.user?.email !== newUser?.email || state.user?.phone_number !== newUser?.phone_number) {
            setDisableEdit(false)
        }
        else {
            setDisableEdit(true)
        }
    }, [newUser, state, selectedFiles])

    return (
        <TabPane tabId={1}>
            <AvForm
                onValidSubmit={() => {
                    updateInformations(newUser)
                }}
            >
                <Row>
                    <Col>
                        <AvField
                            className="mb-3"
                            name="First name"
                            label="First name"
                            placeholder="Type Something"
                            type="text"
                            errorMessage="First name is required."
                            validate={{ required: { value: true } }}
                            value={newUser?.first_name}
                            onChange={(e) => { setNewUser({ ...newUser, first_name: e.target.value }); }}
                        />
                    </Col>
                    <Col>
                        <AvField
                            className="mb-3"
                            name="Last name"
                            label="Last name"
                            placeholder="Type Something"
                            type="text"
                            errorMessage="Last name is required."
                            validate={{ required: { value: true } }}
                            value={newUser?.last_name}
                            onChange={(e) => { setNewUser({ ...newUser, last_name: e.target.value }); }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AvField
                            className="mb-3"
                            name="Email"
                            label="Email"
                            placeholder="Email"
                            type="text"
                            errorMessage="Invalid Email!"
                            validate={{
                                required: { value: true },
                                email: { value: true },
                            }}
                            value={newUser?.email}
                            onChange={(e) => { setNewUser({ ...newUser, email: e.target.value }); }}
                        />
                    </Col>
                    <Col>
                        <AvField
                            className="mb-3"
                            name="Phone number"
                            label="Phone number"
                            placeholder="Type Something"
                            type="text"
                            errorMessage="Phone number is required"
                            validate={{
                                required: { value: true },
                                pattern: {
                                    value: "^[0-9]{8}$",
                                    errorMessage: "the number must be composed of 8 digits",
                                },
                            }}
                            value={newUser?.phone_number}
                            onChange={(e) => { setNewUser({ ...newUser, phone_number: e.target.value }); }}
                        />
                    </Col>
                </Row>
                <Row>
                        <Col>
                            <Dropzone
                                style={{ height: '5rem' }}
                                onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles)
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>Drop your profile picture here or click to upload.</h4>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>

                        </Col>
                        <Col>
                            {selectedFiles &&
                                <>
                                    <img
                                        style={{ width: '12rem', height: '14rem' }}
                                        data-dz-thumbnail=""
                                        className="avatar-sm rounded bg-light"
                                        alt={selectedFiles.name}
                                        src={selectedFiles.preview}
                                    />
                                </>
                            }
                        </Col>
                    </Row>

                <FormGroup className="mb-0" style={{ float: 'right' }}>
                    <Button
                        type="submit"
                        disabled={DisableEdit}
                        color="primary"
                        className="ms-1"
                    >
                        Update informations
                    </Button>
                </FormGroup>
            </AvForm>
        </TabPane>
    )
}
