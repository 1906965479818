import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Label, Modal, UncontrolledAlert } from 'reactstrap'
import Select from "react-select"
import { upsertCustomField } from 'store/customFields/services';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomFieldsByTableNameAsync } from 'store/customFields/actions';

const options = [
    { value: "String", label: "String" },
    { value: "Integer", label: "Integer" },
    { value: "Double", label: "Double" },
    { value: "Date", label: "Date" },
]

export default function CustomField(props) {

    const { table_name } = props;
    const dispatch = useDispatch()
    const [customField, setCustomField] = useState({ name: '', type: '', tableName: table_name })
    const [modal_standard, setmodal_standard] = useState(false)
    const [selectedGroup, setselectedGroup] = useState(null)
    const [formAlert, setFormAlert] = useState(false)

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    function tog_standard() {
        if (!modal_standard) {
            setmodal_standard(true)
        } else {
            if (!customField.name || !customField.type) {
                setFormAlert(true)
            } else {
                upsertCustomField(customField).then(async ()=>{
                    dispatch(await getCustomFieldsByTableNameAsync("Store"))
                })
                setmodal_standard(false)
            }
        }
        document.body.classList.add("no_padding")
    }

    return (
        <div>
            <button
                type="button"
                onClick={() => { tog_standard() }}
                className="btn btn-danger waves-effect waves-light"
                data-toggle="modal"
                data-target="#myModal"
            >
                new Field
            </button>
            <Modal
                isOpen={modal_standard}
                toggle={() => { tog_standard() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Add new field
                    </h5>
                    <button
                        type="button"
                        onClick={() => { setmodal_standard(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                        Field name
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Field name"
                        value={customField.name}
                        onChange={(e) => { setCustomField({ ...customField, name: e.target.value }); setFormAlert(false) }}
                    />
                    <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                            placeholder="Type"
                            options={options}
                            classNamePrefix="select2-selection"
                            value={selectedGroup}
                            onChange={(e) => { handleSelectGroup(); setCustomField({ ...customField, type: e.value }); setFormAlert(false); }}
                        />
                    </div>
                    {formAlert &&
                        <UncontrolledAlert
                            color="danger"
                            className="alert-dismissible fade show"
                            role="alert"
                        >
                            <i className="mdi mdi-block-helper me-2"></i> Invalid credentials
                        </UncontrolledAlert>
                    }
                    <FormGroup className="mb-0" style={{ float: 'right' }}>
                        <Button
                            type="button"
                            color="primary"
                            className="ms-1"
                            onClick={()=>{tog_standard()}}
                        >
                            Create field
                        </Button>
                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}
