import AvField from 'availity-reactstrap-validation/lib/AvField'
import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Modal, Row } from 'reactstrap'
import ImageUpload from 'shared/ImageUpload'
import MultipleUploadSlide from 'shared/MultipleUploadSlide'
import MutipleImagesUpload from 'shared/MutipleImagesUpload'
import { upsertProduct } from 'store/product/services'
import { getStoreGroupsAsync } from 'store/storeGroup/actions'
import Form from './Form'

export default function CreateProduct(props) {

    const { getProducts } = props
    const dispatch = useDispatch()
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [storeGroups, setStoreGroups] = useState([])
    const [sgp, setSgp] = useState([])
    const [product, setProduct] = useState({})
    const [brandId, setbrandId] = useState(0)
    const [categoryId, setCategoryId] = useState(0)
    const [selectedFile, setselectedFile] = useState(null)
    const [selectedFiles, setselectedFiles] = useState(null)

    const createProduct = async () => {
        upsertProduct(product, sgp, "create", brandId, categoryId, "products", selectedFile, selectedFiles)
        .then(()=>{ getProducts() })
    }

    const getStoreGroups = async () => {
        setStoreGroups(dispatch(await getStoreGroupsAsync()).payload.storeGroups)
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        setselectedFile(null)
        setselectedFiles(null)
        setProduct({})
        document.body.classList.add("no_padding")
    }

    useEffect(async () => {
        await getStoreGroups()
    }, [])

    useEffect(() => {
        let obj = {};
        if (sgp.length === 0) {
            storeGroups.map((sg) => {
                sgp.push({ ...obj, storeGroup: sg, internalCode: 0 })
            })
        }
        setSgp(sgp)
    }, [storeGroups])

    return (
        <>
            <Link to="#">
                <i
                    className="bx bx-list-plus"
                    style={{ fontSize: "25px", float: "right", marginTop: "-2rem", cursor: "pointer" }}
                    data-toggle="modal"
                    onClick={() => { tog_fullscreen() }}
                ></i>
            </Link>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
                className="modal-fullscreen"
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Create product
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        onValidSubmit={() => {
                                            createProduct().then(() => { tog_fullscreen() })
                                        }}
                                    >
                                        {/* Form component */}
                                        <Row>
                                            <Form product={product} setProduct={setProduct} setbrandId={setbrandId} setCategoryId={setCategoryId} />
                                            {sgp.map((group, index) => {
                                                return (
                                                    <Col key={index} sm="6">
                                                        <AvField
                                                            className="mb-3"
                                                            name={"internal code " + group.storeGroup?.name}
                                                            label={"Internal code " + group.storeGroup?.name}
                                                            placeholder={"Internal code " + group.storeGroup?.name}
                                                            type="text"
                                                            errorMessage="Internal code is required."
                                                            value={group.internalCode}
                                                            onChange={(e) => { group.internalCode = e.target.value }}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        {/* upload image */}
                                        <Row style={{ marginBottom: "1rem" }}>
                                            <Col xs="12" md="6">
                                                <h5>Product picture :</h5>
                                                <ImageUpload selectedFile={selectedFile} setselectedFile={setselectedFile} />
                                            </Col>
                                            {selectedFile &&
                                                <Col>
                                                    <img
                                                        style={{ width: 'auto', height: '14rem', marginTop: "2.3rem" }}
                                                        data-dz-thumbnail=""
                                                        className="avatar-sm rounded bg-light"
                                                        alt={selectedFile.name}
                                                        src={selectedFile.preview}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                        {/* upload multiple */}
                                        <MultipleUploadSlide selectedFiles={selectedFiles} />
                                        <Row>
                                            <Col>
                                                {/* Multiple upload component */}
                                                <MutipleImagesUpload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} />
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-wrap gap-2" style={{ float: "right", marginTop: "2rem", marginBottom: "-2.5rem" }}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn waves-effect waves-light"
                                            >
                                                Create product
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
