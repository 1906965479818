import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatarMale from "../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../assets/images/users/female-profile-pic.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { asyncUpsertUser } from 'store/user/actions';
import PositionedSweetAlert from 'shared/PositionedSweetAlert';
import bcrypt from 'bcryptjs'
import UpdateInformations from './components/UpdateInformations';
import UpdatePassword from './components/UpdatePassword';
import classnames from "classnames"

export default function UserProfile() {

  let state = useSelector(state => state.User);
  const dispatch = useDispatch();
  const [user, setUser] = useState({})
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [avatar, setAvatar] = useState(null)
  const [alert, setAlert] = useState(false)
  const [DisableEdit, setDisableEdit] = useState(true)
  const [DisablePw, setDisablePw] = useState(true)
  const [activeTabVartical, setoggleTabVertical] = useState(1)


  const updateProfile = async (user, roleId) => {
    const res = dispatch(await asyncUpsertUser(user, roleId));
    if (res) {
      setAlert(true)
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
  }

  useEffect(() => {
    setUser(state.user); // set user from store
    if (user?.gender === 'M' && !user?.profile_picture) {
      setAvatar(avatarMale);
    } else if (user?.gender === 'F' && !user?.profile_picture) {
      setAvatar(avatarFemale);
    }
    else {
      setAvatar(user?.profile_picture)
    }
    setDisableEdit(true)
  }, [state, user])

  useEffect(async () => {
      if (state.user?.first_name !== user?.first_name || state.user?.last_name !== user?.last_name || state.user?.email !== user?.email || state.user?.phone_number !== user?.phone_number) {
        await setDisableEdit(false)
      }
  }, [user, state])

  useEffect(async () => {
    if (oldPassword === confirmPassword && newPassword) {
      bcrypt.compare(oldPassword, user?.password, async (err, res) => {
        if (res) {
          await setDisablePw(false)
        } else { await setDisablePw(true) }
      });
    } else {
      await setDisablePw(true)
    }
  }, [oldPassword, confirmPassword, newPassword])

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="GTM" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <Media>
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    {user &&
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>{user?.first_name + " " + user?.last_name}</h5>
                          <p className="mb-1">Email : {user?.email}</p>
                          <p className="mb-0">Username : {user?.username}</p>
                        </div>
                      </Media>
                    }
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h4 className="card-title mb-4">Update informations</h4>
          <Card>
            <CardBody>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({
                        current: activeTabVartical === 1,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabVartical === 1,
                        })}
                        onClick={() => {
                          toggleTabVertical(1)
                        }}
                      >
                        <span className="number">1</span>{" "}
                        informations
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({
                        current: activeTabVartical === 2,
                      })}>
                      <NavLink
                        className={classnames({
                          active: activeTabVartical === 2,
                        })}
                        onClick={() => {
                          toggleTabVertical(2)
                        }}
                      >
                        <span className="number ms-2">2</span>{" "}
                        Update password
                      </NavLink>
                    </NavItem>
                  </ul>
                  <div className="content clearfix">
                    <TabContent
                      activeTab={activeTabVartical}
                      className="body"
                    >
                      <UpdateInformations state={state} user={user} setUser={setUser} DisableEdit={DisableEdit} setDisableEdit={setDisableEdit} updateProfile={updateProfile} />
                      <UpdatePassword user={user} DisablePw={DisablePw} oldPassword={oldPassword} setOldPassword={setOldPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} newPassword={newPassword} setNewPassword={setNewPassword} updateProfile={updateProfile} />
                    </TabContent>
                  </div>

                </div>
              </div>
              {alert &&
                <PositionedSweetAlert setAlert={setAlert} />
              }
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}