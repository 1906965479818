import { API_URL, api } from "helpers/consts";

/**
 * 
 * @returns Product list
 */
export const getProducts = async () => {
    const res = await api.get(`${API_URL}/product`);
    return res.data;
};

/**
 * 
 * @param {Product} product 
 * @param {StoreGroup[]} groups 
 * @param {int} brandId 
 * @param {int} categoryId 
 * @param {formData} selectedFile 
 * @param {formData} selectedFiles 
 */
export const upsertProduct = async (product, groups, action, brandId, categoryId, products, selectedFile, selectedFiles) => {
    const formData = new FormData();

    formData.append("product", JSON.stringify(product))
    formData.append("action", action)
    formData.append("file", selectedFile)
    if (selectedFiles) {
        selectedFiles?.forEach(element => {
            formData.append("files", element)
        });
    }
    groups?.forEach(element => {
        formData.append("storeGroupProducts", JSON.stringify(element))
    });

    await api.post(`${API_URL}/product/${products}/${brandId}/${categoryId}`, formData)
};