import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { Button, FormGroup, Label, Modal, Row } from "reactstrap"
import { getRolesList } from "store/roles-permissions/actions";
import generator from "generate-password";
import bcrypt from "bcryptjs"
import { AvForm, AvField } from "availity-reactstrap-validation"

export default function UserModal(props) {

    const { modalScroll, setModalScroll, tog_scroll, createUserAccount } = props;
    const dispatch = useDispatch();

    const [user, setUser] = useState({});
    const [roles, setRoles] = useState(null);
    const [role, setRole] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [password, setPassword] = useState("");

    const resetUser = () => {
        setUser({
            ...user,
            phone_number: "",
            email: "",
            first_name: "",
            last_name: "",
            disabled: 0,
            gender: ""
        });
        setRole(null);
        setPassword("");
    }

    useEffect(async () => {
        if (user.first_name && user.last_name && /\S+@\S+\.\S+/.test(user.email) && String(user.phone_number).length === 8 && user.gender && role) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [user, role])

    useEffect(async () => {
        setRoles(dispatch(await getRolesList()).payload.roles);
        resetUser();
        if (modalScroll) {
            setPassword(generator.generate({
                length: 10,
                numbers: true,
                strict: true,
                symbols: true
            }));
        }
    }, [modalScroll])

    return (
        <Modal
            isOpen={modalScroll}
            toggle={() => { tog_scroll() }}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    Create new user
                </h5>
                <button
                    type="button"
                    onClick={() => setModalScroll(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                </button>
            </div>
            <div className="modal-body">
                <AvForm
                    onValidSubmit={() => {
                        createUserAccount(
                            {
                                ...user,
                                username: String(user.first_name).split(' ').join('.') + '.' + user.last_name[0],
                                disabled: 0,
                                password: bcrypt.hashSync(password, 12)
                            },
                            role,
                            password
                        );
                        resetUser();
                        setModalScroll(false);
                    }}
                >
                    <AvField
                        className="mb-3"
                        name="First name"
                        label="First name"
                        placeholder="First name"
                        type="text"
                        errorMessage="First name is required."
                        validate={{ required: { value: true } }}
                        value={user.first_name}
                        onChange={(e) => { setUser({ ...user, first_name: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="Last name"
                        label="Last name"
                        placeholder="Last name"
                        type="text"
                        errorMessage="Last name is required."
                        validate={{ required: { value: true } }}
                        value={user.last_name}
                        onChange={(e) => { setUser({ ...user, last_name: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        errorMessage="Invalid Email"
                        validate={{
                            required: { value: true },
                            email: { value: true },
                        }}
                        value={user.email}
                        onChange={(e) => { setUser({ ...user, email: e.target.value }); }}
                    />
                    <AvField
                        className="mb-3"
                        name="Phone number"
                        label="Phone number"
                        placeholder="Phone number"
                        type="number"
                        errorMessage="Enter Only Number"
                        validate={{
                            required: { value: true },
                            pattern: {
                                value: "^[0-9]{8}$",
                                errorMessage: "the number must be composed of 8 digits",
                            },
                        }}
                        value={user.phone_number}
                        onChange={(e) => { setUser({ ...user, phone_number: e.target.value }); }}
                    />
                    <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Gender</Label>
                        <Row>
                            <div style={{ marginLeft: '1rem' }} className="form-check form-radio-outline form-radio-primary mb-3">
                                <label className="form-check-label"> Male </label>
                                <input
                                    type="radio"
                                    name="customRadiooutlinecolor1"
                                    className="form-check-input"
                                    value="M"
                                    onChange={(e) => { setUser({ ...user, gender: e.target.value }); }}
                                />
                            </div>
                            <div style={{ marginLeft: '5rem', marginTop: '-2.2rem' }} className="form-check form-radio-outline form-radio-danger mb-3">
                                <label className="form-check-label"> Female </label>
                                <input
                                    type="radio"
                                    name="customRadiooutlinecolor1"
                                    className="form-check-input"
                                    value="F"
                                    onChange={(e) => { setUser({ ...user, gender: e.target.value }); }}
                                />
                            </div>
                        </Row>
                    </div>
                    <AvField
                        className="mb-3"
                        name="Role"
                        label="Role"
                        placeholder="Enter Only number"
                        type="select"
                        errorMessage="Role is required"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={(e) => { setRole(e.target.value); }}
                    >
                        <option>Choose role...</option>
                        {roles?.map((role, index) => (
                            <option key={index} value={role.id}>{role.role}</option>
                        ))}

                    </AvField>
                    <FormGroup className="mb-0" style={{ float: 'right' }}>
                            <Button
                                type="reset"
                                color="danger"
                                onClick={() => { setModalScroll(false); }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="primary"
                                className="ms-1"
                            >
                                Create User
                            </Button>
                    </FormGroup>
                </AvForm>
            </div>
        </Modal>
    )
}