import { API_URL, api } from "helpers/consts";

/**
 * 
 * @returns stores list
 */
export const getStores = async () => {
    const res = await api.get(`${API_URL}/store/`);
    return res.data;
}

/**
 * 
 * @param {Integer} id 
 * @returns store by id
 */
export const getStoreById = async (id) => {
    const res = await api.get(`${API_URL}/store/${id}`);
    return res.data;
}

/**
 * 
 * @param {Store} store 
 * @returns created store
 */
export const upsertStore = async (store) => {
    const res = await api.post(`${API_URL}/store/`, store)
    return res.data;
}

/**
 * 
 * @param {files} array of picturesfiles 
 * @param {int} storeId
 * @param {Folder} dir folder when we store images 
 * @returns images uploaded
 */
export const createStorePictures = async (files, storeId, dir) => {
    const formData = new FormData();
    files.forEach(element => {
        formData.append('files', element)
    });
    const res = await api.post(`${API_URL}/store/uploadMultipleFiles/${storeId}/${dir}`, formData)
    return res.data;
}

