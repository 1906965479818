import { getRoles, getUserByUsername, getUsers, upsertUser } from "store/user/services"
export const GET_PROFILE = "GET_PROFILE"
export const UPDATE_USER = "UPDATE_USER"
export const GET_USERS_LIST = "GET_USERS_LIST"
export const GET_ROLES_LIST = "GET_ROLES_LIST"

export const asyncUpsertUser = async (user, roleId) => {
  const res = await upsertUser(user, roleId);
  return {
    type: UPDATE_USER,
    payload: { user: res },
  }
}

/**
 * @param {Object} user 
 * @description this function log user
 */
export const getUserProfile = async (username) => {
  try {
    const res = await getUserByUsername(username);
    return {
      type: GET_PROFILE,
      payload: { user: res },
    }
  } catch (error) {
    return {
      type: GET_PROFILE,
      payload: { user: null },
    }
  }

}

/**
 * 
 * @returns users list
 */
export const getUsersList = async () => {
  const res = await getUsers();
  return {
    type: GET_USERS_LIST,
    payload: { usersList: res },
  }
}

