import React from 'react'
import { TabPane, FormGroup, Button } from 'reactstrap'
import { AvForm, AvField } from "availity-reactstrap-validation"
import bcrypt from 'bcryptjs';

export default function UpdatePassword(props) {

    const { DisablePw, user, oldPassword, setOldPassword, newPassword, setNewPassword, confirmPassword, setConfirmPassword, updateProfile } = props;
    return (
        <TabPane tabId={2}>
            <AvForm
                onValidSubmit={() => {
                    updateProfile({ ...user, password: bcrypt.hashSync(newPassword, 12) }, user.role.id);
                }}
            >
                <AvField
                    className="mb-3"
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    errorMessage="Enter password"
                    validate={{ required: { value: true } }}
                    value={oldPassword}
                    onChange={(e) => { setOldPassword(e.target.value) }}
                />
                <AvField
                    className="mb-3"
                    label="Confirm password"
                    name="password1"
                    type="password"
                    placeholder="Re-type password"
                    errorMessage="Re-enter password"
                    validate={{
                        required: { value: true },
                        match: { value: "password" },
                    }}
                    value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                />
                <AvField
                    className="mb-3"
                    label="New password"
                    name="newpassword"
                    type="password"
                    placeholder="New password"
                    errorMessage="Enter your password"
                    validate={{ required: { value: true }, }}
                    value={newPassword}
                    onChange={(e) => { setNewPassword(e.target.value) }}
                />
                <FormGroup className="mb-0" style={{ float: 'right' }}>
                    <div>
                        <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={DisablePw}
                        >
                            Update password
                        </Button>
                    </div>
                </FormGroup>
            </AvForm>

        </TabPane>

    )
}
