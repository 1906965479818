import { getStoreById, getStores } from "./services";

export const GET_STORES = "GET_STORES"
export const GET_STORE = "GET_STORE"

/**
 * 
 * @returns stores list
 */
export const getStoresAsync = async () => {
  try {
    const res = await getStores();
    return {
      type: GET_STORES,
      payload: { stores: res },
    }
  } catch (error) {
    return error
  }

}

/**
 * 
 * @param {Integer} id store id 
 * @returns store by id
 */
 export const getStoreByIdAsync = async (id) => {
  try {
    const res = await getStoreById(id);
    return {
      type: GET_STORE,
      payload: { store: res },
    }
  } catch (error) {
    return error
  }

}