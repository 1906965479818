import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'

export default function MultipleUploadSlide(props) {

    const { selectedFiles } = props
    const [visibleImages, setVisibleImages] = useState(0)
    
    return (
        <>
            <h5>Images :</h5>
            <div className="zoom-gallery" style={{ marginBottom: "1rem" }}>
                <Row style={{ marginTop: "1rem" }}>
                    {selectedFiles && selectedFiles?.length > 0 &&
                        <Col>
                            <i
                                className="mdi mdi-chevron-left"
                                style={{ fontSize: "2.5rem", cursor: "pointer", float: "right", marginTop: "3rem", marginRight: "2.5%" }}
                                onClick={() => { visibleImages - 9 >= 0 && setVisibleImages(visibleImages - 10) }}
                            />
                        </Col>
                    }
                    {selectedFiles && selectedFiles?.map((picture, index) => {
                        if (index < visibleImages + 10 && index >= visibleImages) {
                            return (
                                <Col>
                                    <img
                                        style={{ width: "8rem", height: "10rem", marginRight: "1%" }}
                                        data-dz-thumbnail=""
                                        className="avatar-sm rounded bg-light"
                                        alt={selectedFiles && picture.name}
                                        src={selectedFiles && picture.preview}
                                    />
                                </Col>
                            )
                        }
                    })}
                    {selectedFiles && selectedFiles?.length > 0 &&
                        <Col>
                            <i
                                className="mdi mdi-chevron-right"
                                style={{ fontSize: "2.5rem", cursor: "pointer", float: "left", marginTop: "3rem" }}
                                onClick={() => { visibleImages + 9 <= selectedFiles?.length && setVisibleImages(visibleImages + 10) }}
                            />
                        </Col>
                    }
                </Row>
            </div>
        </>
    )
}
