import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, CardBody, CardTitle, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap"
import { getUsersList } from "store/user/actions";
import UserModal from "./components/UserModal";
import UpdateModal from "./components/UpdateModal";
import avatarMale from "../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../assets/images/users/female-profile-pic.jpg"
import { createAccountMail, upsertUser } from "store/user/services";
import { register_mail, reset_password_mail } from "helpers/consts";
import SweetAlertDisable from "./components/SweetAlertDisable";
import SweetAlertResetPassword from "./components/SweetAlertResetPassword";
import generator from "generate-password";
import Breadcrumbs from "components/Common/Breadcrumb"

export default function UsersList() {

    const dispatch = useDispatch();
    const [modalScroll, setModalScroll] = useState(false)
    const [modal, setModal] = useState(false)
    const [usersList, setUsersList] = useState(null)
    const [filtredUsers, setFiltredUsers] = useState(null)
    const connectedUser = useSelector(state => state.User.user)
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [keyword, setKeyword] = useState("");
    const [currentUser, setCurrentUser] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [password_alert, setpassword_alert] = useState(false)
    const [newPassword, setNewPassword] = useState("")


    const tog_scroll = () => {
        setModalScroll(!modalScroll);
    }

    const tog_Modal = () => {
        setModal(!modal);
    }

    const getUsers = async () => {
        return dispatch(await getUsersList()).payload;
    }

    const createUserAccount = async (user, roleId, password) => {
        await upsertUser(user, roleId)
            .then(async () => {
                setUsersList(dispatch(await getUsersList()).payload.usersList)
                setFiltredUsers(dispatch(await getUsersList()).payload.usersList)
                await createAccountMail(register_mail(user, password))
            })
    }

    const enableUser = (user, roleId) => {
        upsertUser({ ...user, disabled: !user.disabled }, roleId)
            .then(async () => {
                await getUsers().then((res) => {
                    setUsersList(res.usersList);
                    setFiltredUsers(res.usersList);
                })
            })
    }

    const resetPassword = (user, roleId) => {
        upsertUser(
            {
                ...user,
                password: newPassword,
            },
            roleId
        )
            .then(async () => {
                await getUsers().then((res) => {
                    setUsersList(res.usersList);
                    setFiltredUsers(res.usersList);
                })
            }).then(async () => {
                await createAccountMail(reset_password_mail(user, newPassword))
            })
    }

    useEffect(async () => {
        await getUsers().then((res) => {
            setUsersList(res.usersList);
            setFiltredUsers(res.usersList);
        })
    }, [])

    useEffect(async () => {
        const params = ["first_name", "last_name", "phone_number"]
        setFiltredUsers(usersList?.filter(user => {
            for (let index = 0; index < params.length; index++) {
                if (user[params[index]].toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) !== -1) {
                    return user[params[index]].toString().toLowerCase().indexOf(keyword.toString().toLowerCase()) !== -1;
                }
            }
        }))
    }, [keyword])

    return (
        <div className="page-content">
            <MetaTags>
                <title>User list</title>
            </MetaTags>
            <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="User List" />
                <Card>
                    <CardBody>
                        <CardTitle>User List </CardTitle>
                        {connectedUser?.role.role === "admin" &&
                            <i
                               className="bx bx-list-plus"
                                style={{ fontSize: "25px", float: "right", cursor: "pointer", color: '#556EE6', marginTop: "-2rem" }}
                                onClick={() => { tog_scroll(); }}
                                data-toggle="modal"
                            ></i>
                        }
                        <div className="app-search" style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                            <div style={{ width: "30rem", margin: "auto" }} >
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    value={keyword}
                                    onChange={(e) => { setKeyword(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="table-responsive">
                            <UserModal createUserAccount={createUserAccount} tog_scroll={tog_scroll} setModalScroll={setModalScroll} modalScroll={modalScroll} />
                            <Table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Picture</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone number</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredUsers?.map((user, index) => (
                                        <tr key={index}>
                                            <td>
                                                {user.gender === "M" && !user.profile_picture &&
                                                    <img
                                                        src={avatarMale}
                                                        alt=""
                                                        className="avatar-sm rounded-circle img-thumbnail"
                                                    />
                                                }
                                                {user.gender === "F" && !user.profile_picture &&
                                                    <img
                                                        src={avatarFemale}
                                                        alt=""
                                                        className="avatar-sm rounded-circle img-thumbnail"
                                                    />
                                                }
                                                {user.profile_picture &&
                                                    <img
                                                        src={user.profile_picture}
                                                        alt=""
                                                        className="avatar-sm rounded-circle img-thumbnail"
                                                    />
                                                }
                                            </td>
                                            <td><p style={{ marginTop: '1rem' }}>{user.first_name}</p></td>
                                            <td><p style={{ marginTop: '1rem' }}>{user.last_name}</p></td>
                                            <td><p style={{ marginTop: '1rem' }}>{user.phone_number}</p></td>
                                            <td>
                                                {user.disabled ? (
                                                    <Badge
                                                        className={"font-size-11 badge-soft-danger"}
                                                        color="danger"
                                                        pill
                                                        style={{ marginTop: '1rem' }}
                                                    >
                                                        disable
                                                    </Badge>
                                                ) : (
                                                    <Badge
                                                        className={"font-size-11 badge-soft-success"}
                                                        color="success"
                                                        pill
                                                        style={{ marginTop: '1rem' }}
                                                    >
                                                        enable
                                                    </Badge>
                                                )}
                                            </td>
                                            <td>
                                                <Dropdown
                                                    isOpen={btnprimary1 === index}
                                                    toggle={() => { btnprimary1 === index ? (setBtnprimary1(null)) : (setBtnprimary1(index)) }}
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-primary" onClick={() => { setCurrentUser(user); }}>
                                                        Actions <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => { setModal(true) }}>
                                                            Edit user
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                setpassword_alert(true);
                                                                setNewPassword(
                                                                    generator.generate({
                                                                        length: 10,
                                                                        numbers: true,
                                                                        strict: true,
                                                                        symbols: true
                                                                    })
                                                                )
                                                            }}>
                                                            Reset password
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => { setconfirm_alert(true); }}>
                                                            {user.disabled ? ("Enable") : ("Disable")} user
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                    <UpdateModal modal={modal} setModal={setModal} tog_Modal={tog_Modal} currentUser={currentUser} setUsersList={setUsersList} setFiltredUsers={setFiltredUsers} />
                                                    <SweetAlertDisable confirm_alert={confirm_alert} setconfirm_alert={setconfirm_alert} currentUser={currentUser} enableUser={enableUser} />
                                                    <SweetAlertResetPassword password_alert={password_alert} setpassword_alert={setpassword_alert} currentUser={currentUser} resetPassword={resetPassword} />
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}
