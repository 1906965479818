import { API_URL, api } from "helpers/consts";

/**
 * 
 * @returns roles list
 */
 export const getRoles = async () => {
    const res = await api.get(`${API_URL}/role/`);
    return res.data;
}

/**
 * 
 * @param {Permission} permission 
 * @param {int} roleId 
 * @returns Permission
 */
export const upsertPermission = async (permission, roleId) => {
    const res = await api.post(`${API_URL}/permission/${roleId}`, permission)
    return res.data;
}

/**
 * 
 * @param {Role} role 
 * @returns  Role
 */
export const upsertRole = async (role) => {
    const res = await api.post(`${API_URL}/role/`, role)
    return res.data;
}