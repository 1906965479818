import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

export default function SweetAlertDisable(props) {

    const {confirm_alert, setconfirm_alert, currentUser, enableUser} = props;
    const [success_dlg, setsuccess_dlg] = useState(false)
    const [error_dlg, seterror_dlg] = useState(false)
    const [dynamic_title, setdynamic_title] = useState("")
    const [dynamic_description, setdynamic_description] = useState("")
    return (
        <>
            {confirm_alert &&
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText={currentUser.disabled?("Yes, enable it!"):("Yes, disable it!")}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        enableUser(currentUser, currentUser.role.id)
                        setconfirm_alert(false)
                        setsuccess_dlg(true)
                        setdynamic_title(currentUser.disabled?("Enabled"):("Disabled"))
                        setdynamic_description(currentUser.disabled?("the account has been enabled."):("the account has been disabled."))
                    }}
                    onCancel={() => { setconfirm_alert(false); }}
                >
                    {currentUser.disabled?("enable this user!"):("disable this user!")}
                </SweetAlert>
            }
            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}

            {error_dlg ? (
                <SweetAlert
                    error
                    title={dynamic_title}
                    onConfirm={() => {
                        seterror_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}
        </>
    )
}
