import { API_URL, api } from "helpers/consts";

/**
 * 
 * @param {User} user 
 * @returns 
 */
 export const upsertUser = async (user, roleId) => {
    const res = await api.post(`${API_URL}/user/${roleId}`, user);
    return res.data;
}

/**
 * 
 * @param {Object} email 
 * @returns 
 */
export const createAccountMail = async (email) =>{
    const res = await api.post(`${API_URL}/sendMail/${email.receiver}/${email.subject}/${email.text}`)
    return res;
}

export const uploadImage = async (file, dir) => {
    const formData = new FormData();
    formData.append('file', file)
    const res = await api.post(`${API_URL}/uploadFile/${dir}`, formData)
    return res;
}

/**
 * 
 * @param {String} username 
 * @returns user by username
 */
 export const getUserByUsername = async (username) => {
    const res = await api.get(`${API_URL}/user/${username}`);
    return res.data;
}

/**
 * 
 * @returns users list
 */
export const getUsers = async () => {
    const res = await api.get(`${API_URL}/user/`);
    return res.data;
}